code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body,
ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
table,
td,
img,
div,
dl,
dt,
dd,
input,
figure {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #656565;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  -webkit-overflow-scrolling: touch;
  text-align: left;
  color: #4B4B4B;
}

a,
a:hover {
  text-decoration: none;
  outline: none;
}

li {
  list-style: none;
}

img {
  border: none;
}

input,
select,
textarea {
  border: none;
  outline: none;
  background: none;
}

em,
i {
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

#root{
  width: 100%;
  height: 100%;
}


$fff:#fff;
$blue:#063D76;
$blue409:#0C4DA2;
$color4B:#4B4B4B;
$color94:#949494;
$yellowF0:#F08E00;
$colorA6:#A6A6A6;
$colorB9:#B9B9B9;
$redE7:#E72233;
$redFA:#FA4755;
$borderColor:#E2E2E2;
$logoBg:rgba(30,51,89,.35);
$divBg:#F7F7F7;
$divDc:#DCDCDC;
$borderF1:#F1F1F1;
.session{
  width: 1250px;
  margin: auto;
  padding: 16px 0;
}

.site-input-left{
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.site-input-split{
  border-left: none;
  border-right: none;
  border-radius: 0;
  background: $fff !important;
}
.site-input-right{
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-right: 10px;
}
.ant-input-number-handler-wrap{
  display: none;
}
.site-input-left:hover,.site-input-right:hover{
  border:solid 1px $blue409;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper{
  margin-left: 0;
}
.ant-checkbox-wrapper{
  margin-right: 20px;
  width: 118px;
}
.pagination-box{
  width: 100%;
  text-align: center;
}

